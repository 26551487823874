import React, {useState} from 'react';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import {Card} from 'src/components/ui/card';
import styles from './index.module.scss';

export default (props) => {
  const [loading, setLoading] = useState(false);

  let username;
  const path = props.location.pathname;
  if (path.length > 3) {
    username = path.substring(3, path.length);
  }

  if (loading) {
    return skeleton;
  }

  return (
    <Layout>
      <SEO title={`@${username}`} />
      <div>
        <Card className={styles.profile}>
          {!username && <div>Profile not found</div>}
          {username && <div>@{username}</div>}
        </Card>
      </div>
    </Layout>
  );
};

const skeleton = (
  <Layout>
    <div>Loading...</div>
  </Layout>
);
